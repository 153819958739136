<template>
  <svg width="732"
       height="732"
       viewBox="0 0 732 732"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M366 0C163.836 0 0 163.836 0 366C0 568.164 163.836 732 366 732C568.164 732 732 568.164 732 366C732 163.836 568.114 0 366 0ZM366 554.511C261.89 554.511 177.489 470.11 177.489 366C177.489 261.89 261.89 177.489 366 177.489C470.11 177.489 554.511 261.89 554.511 366C554.511 470.11 470.11 554.511 366 554.511Z"
          fill="#82F03C"
          fill-opacity="0.9"
    />
  </svg>
</template>
